<template>
    <div>
        <div>{{langRes.date}} {{date}}</div>
        <div>{{langRes.time}} {{time}}</div>
    </div>
</template>

<script>
    import moment from 'moment'
    moment.locale('zh-cn')
    export default {
        props:["langRes"],
        data(){
            return {
                date:"",
                time:"",
                interval:""
            }
        },
        created() {
            this.updateTime()
            setInterval(()=>{
                this.updateTime()
            },1000)
        },
        methods:{
            updateTime(){
                this.date = moment().format(this.langRes.dateFormat)
                this.time = moment().format(this.langRes.timeFormat)
            }
        },
        destroyed() {
            clearInterval(this.interval)
        }
    }
</script>

<style lang="less" scoped>
.time{

}
</style>
